'use client';

import { isPathMatch } from '@repo-shared-base/utils/menu-path-util';

let seq_ = 0;
function nextId() {
  return ++seq_;
}

function attachIds(items: Omit<SUBMENU_TYPE, 'id'>[]): SUBMENU_TYPE[] {
  return items.map((item) => ({ ...item, id: nextId() }));
}

export const MENU_SECTIONS = [
  {
    menuId: 'subject',
    title: '교과목',
  },
  {
    menuId: 'ai',
    title: 'AI학습',
  },
  {
    menuId: 'block',
    title: '코딩',
  },
  {
    menuId: 'community',
    title: '커뮤니티',
  },
  {
    menuId: 'about',
    title: 'About Us',
  },
];

interface SUBMENU_TYPE {
  id: number | string;
  title: string;
  href: string;
  match?: (path: string) => boolean;
}

export const SUBMENUS_ARRAY: SUBMENU_TYPE[][] = [
  [
    {
      title: '초등',
      href: '/dtb/list/A01',
      match: isPathMatch({ exact: '/dtb/list/A01', starting: '/dtb/list/A01/' }),
    },
    {
      title: '중등',
      href: '/dtb/list/B01',
      match: isPathMatch({ exact: '/dtb/list/B01', starting: '/dtb/list/B01/' }),
    },
    {
      title: '고등',
      href: '/dtb/list/C01',
      match: isPathMatch({ exact: '/dtb/list/C01', starting: '/dtb/list/C01/' }),
    },
  ],
  [
    {
      title: 'AI 학습',
      href: '/ai-learn/list/supervised',
    },
    {
      title: '데이터 과학',
      href: '/ai-learn/list/data-science',
    },
    {
      title: '실험실',
      href: '/dev-labs',
    },
  ],
  [
    {
      title: '코딩하기',
      href: '/coding/list/block',
    },
    {
      title: '나의 코딩',
      href: '/coding/mine',
    },
    {
      title: '튜터',
      href: '/tutor/intro',
    },
  ],
  [
    {
      title: '클래스룸',
      href: '/classroom',
    },
    {
      title: '스터디그룹',
      href: '/studygroup/bandlist/all',
    },
    {
      title: '오픈강좌',
      href: '/openlecture',
      match: isPathMatch({ exact: '/openlecture', starting: '/openlecture/' }),
    },
    {
      title: '갤러리',
      href: '/gallery/list',
      match: isPathMatch({ exact: '/gallery/list', starting: '/gallery/list/' }),
    },
  ],

  [
    {
      title: 'O-CODE',
      href: '/intro',
    },
    {
      title: '공지사항',
      href: '/notice',
      match: isPathMatch({ exact: '/notice', starting: '/notice/' }),
    },
    {
      title: '문의',
      href: '/ask/qna/list',
      match: isPathMatch({ starting: '/ask/' }),
    },
  ],
].map(attachIds);
